import React, {useEffect, useState} from 'react';
import {Box, Fab} from '@mui/material';
import LeagueItemComponent from "./league-item/league-item.component";
import AddIcon from '@mui/icons-material/Add';
import TicketContainerColored from "./ticket-container-colored/ticket-container-colored";
import {League} from "../../core/domain/league/model/league.model";
import UserApi from "../../core/api/user.api";
import {useAuth} from "../../shared/security/auth-context";

interface DashboardComponentProps {
}

const DashboardComponent: React.FC<DashboardComponentProps> = () => {

    const {loggedUser} = useAuth();
    const [userLeagues, setUserLeagues] = useState<League[]>([]);
    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        await loadUserLeague();
    }

    const loadUserLeague = async () => {
        if(loggedUser) {
            setUserLeagues(await UserApi.fetchUserLeagues(loggedUser.id));
        }
    }

    const handleCreateLeague = () => {
        // Logique pour créer une ligue
        console.log('Création de la ligue...');
    };

    return (
        <Box>

            {userLeagues.map(league => (<TicketContainerColored  key={league.id} header={league.name}>
                    <LeagueItemComponent league={league}></LeagueItemComponent>
                </TicketContainerColored>
            ))}

            {/*            <TicketContainerColored header={'Couz League'}>

                <LeagueItemComponent></LeagueItemComponent>
            </TicketContainerColored>
            <TicketContainerColored header={'Euro 2024 League'}>

                <LeagueItemComponent></LeagueItemComponent>
            </TicketContainerColored>*/}

            <Fab variant="circular"
                 color="primary"
                 onClick={handleCreateLeague}
                 sx={{
                     position: 'fixed',
                     bottom: '16px',
                     right: '16px',
                     zIndex: 1000
                 }}>
                <AddIcon/>
            </Fab>
        </Box>
    );
}

export default DashboardComponent;