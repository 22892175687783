import dayjs from "dayjs";
import {Box, Divider, Grid, IconButton, Paper} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {TeamLogo} from "../../../shared/team/team-logo/team-logo";
import React from "react";
import {Fixture} from "../../../core/domain/fixture/fixture.model";
import {BetStatus, UserBet} from "../../../core/domain/bet/model/user-bet.model";
import {BetResultLabel} from "../bet-form/bet-result-label/bet-result-label.component";
import {red} from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";

export interface BetItemProps {
    fixture: Fixture;
    editBet: (fixture: Fixture, userBet?: UserBet) => void;
    userBet?: UserBet
}

export const BetItem = (props: BetItemProps) => {
    const {fixture, editBet, userBet} = props

    const formattedDate = dayjs(fixture.date).format("DD/MM/YYYY HH:mm");
    const isMatchNotStarted = dayjs().isBefore(dayjs(fixture.date));
    /*    let betStatus;
        if(!!userBet && isNotNullOrUndefined(userBet.result)) {
            betStatus = calculateBetStatus(userBet.result, fixture)
        }*/

    const color = (function () {
        switch (userBet?.status) {
            case BetStatus.LOST: {
                return 'error.main'
            }
            case BetStatus.WIN: {
                return 'success.main'
            }

            default: {
                return 'text.primary'
            }
        }
    })();
    return (
        <Grid item xs={12}>
            <Box>
            {/*<Paper elevation={1} sx={{p: 1, mt: 3}}>*/}
                {isMatchNotStarted && fixture.odds && <Grid container justifyContent="flex-end">
                    <Grid item>
                        <IconButton color="primary" onClick={() => editBet(fixture, userBet)}>
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Grid>
                </Grid>}
                <Grid container>
                    <Grid item xs={5}>
                        <TeamLogo teamName={fixture.teams.home.name} logoUrl={fixture.teams.home.logo}></TeamLogo>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{fontSize: 'h6.fontSize'}}>{fixture.score.home} - {fixture.score.away}</Box>
                    </Grid>
                    <Grid item xs={5}>
                        <TeamLogo teamName={fixture.teams.away.name} logoUrl={fixture.teams.away.logo}></TeamLogo>
                    </Grid>
                </Grid>
                <div className={'mt-2'}>{formattedDate}</div>
                {!userBet && !isMatchNotStarted
                    && (
                        <Box sx={{display: 'flex', alignItems: 'center', ml: 1,  color: 'error.main',justifyContent:'center'}}>
                            <div>Aucun pari enregistré pour ce match </div>
                            <CloseIcon sx={{ml: 1, color: red[500]}}></CloseIcon>
                        </Box>

                         )}
                {userBet &&
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 1,
                                m: 1,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            <div>Mon Prono :</div>
                            <BetResultLabel betResult={userBet.result} fixture={fixture}
                                            betStatus={userBet.status}></BetResultLabel>
                        </Box>
                        <Paper elevation={4} sx={{mt: 1, mx: 1, color: color}}>
                            <Grid container sx={{py: 2}} columns={13}>
                                <Grid item xs={4}>
                                    <Box sx={{fontWeight: 'medium'}}>Mise</Box>
                                    <div>{userBet.amount}</div>
                                </Grid>
                                <Divider orientation="vertical" flexItem/>
                                <Grid item xs={4}>
                                    <Box sx={{fontWeight: 'medium'}}>Cote</Box>
                                    <div>{userBet.selectedOdds}</div>
                                </Grid>
                                <Divider orientation="vertical" flexItem/>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{fontWeight: 'medium'}}>{userBet.status === BetStatus.PENDING ? "Gains" : "Gains"}</Box>
                                    <div>{Math.round(userBet.amount * userBet.selectedOdds).toFixed(2)}K</div>
                                </Grid>
                            </Grid>
                        </Paper></>
                }
            {/*</Paper>*/}
            </Box>
        <hr style={{ marginTop: '1rem', marginBottom: '2rem'}}/>
        </Grid>

    )
}