import {Box, Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import {yellow} from "@mui/material/colors";
import {League} from "../../../core/domain/league/model/league.model";
import {useAuth} from "../../../shared/security/auth-context";


export interface LeagueOverwiewProps {
    league: League | null;
}

const LeagueOverview = (props: LeagueOverwiewProps) => {
    const {loggedUser} = useAuth();
    const {league} = props;
    if (!league) {
        return null;
    }
    league.participants?.sort((a, b) => b.kashAmount - a.kashAmount)
    return (
        <div>
            <div>
                <h1>{league.name}</h1>
            </div>
            <Card sx={{minWidth: 275}}>
                <CardContent>
                    <h2>Classement</h2>
                    {league.currentRound < 100 && <div style={{marginBottom: '1rem'}}>Journée {league.currentRound}</div>}
                    {league.currentRound == 108 && <div style={{marginBottom: '1rem'}}>8eme de finale{league.currentRound}</div>}
                    {league.currentRound == 104 && <div style={{marginBottom: '1rem'}}>Quart de finale{league.currentRound}</div>}
                    {league.currentRound == 102 && <div style={{marginBottom: '1rem'}}>Quart de finale{league.currentRound}</div>}
                    {league.participants?.map((participant, index) => (
                        <div key={participant.userId}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    p: 1,
                                    m: 1
                                }}
                            >
                                <Box sx={{
                                    pr: 2
                                }}><span style={{color: 'grey'}}>{index + 1}.</span></Box>
                                <Box sx={{ flexGrow: 1, textAlign: 'start' }}>
                                    {/* <span style={{float: 'left'}}>{index === 0 && <EmojiEventsTwoToneIcon sx={{color: yellow[500]}}/>}</span>*/}

                                    <span style={{ fontWeight: loggedUser.id === participant.userId ? 'bold' : 'normal' }}>{participant.name}</span>
                                </Box>
                                <Box >
                                    <span >{participant.kashAmount} </span>Khash
                                </Box>
                            </Box>
                            <Divider></Divider>
                        </div>
                    ))
                    }

                </CardContent>
            </Card>

        </div>
    )
}

export default LeagueOverview;