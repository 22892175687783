import {Fixture} from "../fixture/fixture.model";
import {BetResult, BetStatus, UserBet} from "./model/user-bet.model";
import {isNotNullOrUndefined} from "../../utils/object.utils";

export  const calculateOdds = (fixture: Fixture, betResult: BetResult | null): number => {
    if (!fixture.odds || !betResult) {
        return 1;
    }
    let bonus = 1;
    const awayScore = betResult.awayScore ?? -1;
    const homeScore = betResult.homeScore ?? -1
    if (awayScore >= 0 && homeScore >= 0) {
        bonus = 15;
    } else if(betResult.diffGoals && betResult.diffGoals > 0) {
        bonus = 5;
    }
    return (function () {
        switch (betResult.winner) {
            case fixture.teams.home.id: {
                return fixture.odds.home * bonus
            }
            case fixture.teams.away.id: {
                return fixture.odds.away * bonus
            }
            case -1: {
                return fixture.odds.draw * bonus
            }

            default: {
                return 1
            }
        }
    })()

}

export const calculateBetStatus = (betResult: BetResult, fixture: Fixture): BetStatus  => {
    if(fixture.score.away === null || fixture.score.home === null) {
        return BetStatus.PENDING;
    }

    // EXACT SCORE
    if(isNotNullOrUndefined(betResult.awayScore) && isNotNullOrUndefined(betResult.homeScore) ) {
        if(betResult.homeScore === fixture.score.home && betResult.awayScore === fixture.score.away) {
            return BetStatus.WIN
        }
        return BetStatus.LOST
    }

    else if(isNotNullOrUndefined(betResult.diffGoals)) {
        if(betResult.diffGoals === Math.abs(fixture.score.home - fixture.score.away)) {
            return BetStatus.WIN
        }
        return BetStatus.WIN
    }

    else if(
        (betResult.winner === -1 && fixture.score.away === fixture.score.home)
        || (betResult.winner === fixture.teams.home.id && fixture.score.home > fixture.score.away)
        ||(betResult.winner === fixture.teams.away.id && fixture.score.home < fixture.score.away)
    ) {
        return BetStatus.WIN
    }

    return BetStatus.LOST;

}

