import React, {ReactNode} from 'react';
import './ticket-container-colored.scss';
interface DashboardComponentProps {
    children: ReactNode;
    header: string;
}

const TicketContainerColored: React.FC<DashboardComponentProps> = ({children, header}) => {
    return (
        <div className="ticket-container">
            <div className="ticket-header">
                {header}
            </div>
            <div className="ticket-content">
                {children}
            </div>
        </div>
    );
}

export default TicketContainerColored;