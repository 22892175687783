import {Fixture, Team} from "./fixture.model";

export class FixtureUtils {
    static getTeamBy(fixture: Fixture, teamId: number): Team | undefined {

        if (fixture.teams.home.id === teamId) {
            return fixture.teams.home;
        } else if (fixture.teams.away.id === teamId) {
            return fixture.teams.away;
        }
        return undefined;
    }
}