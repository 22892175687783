import React from "react";
import {Competition, League} from "../../../core/domain/league/model/league.model";
import {Avatar, Chip} from "@mui/material";


interface CompetitionLogoComponentProps {
    competition: Competition
}
const CompetitionLogoComponent: React.FC<CompetitionLogoComponentProps> = ({competition}) => {


    return  (
        <>
            {competition.type === 'LIGUE1' && <Chip avatar={<Avatar src="/logo_ligue1-square.png"/>} label={competition.name}
                  variant="filled" sx={{backgroundColor: '#091c3e', color: ' white'}}/>}

            {competition.type === 'EURO' && <Chip avatar={<Avatar src="/logo_euro_2024.png"/>} label={competition.name}
                                                    variant="filled" sx={{backgroundColor: '#091c3e', color: ' white'}}/>}
        </>
    )
};

export default CompetitionLogoComponent;