import React, {createContext, useContext, useEffect, useState} from 'react';
import {User} from "../../core/domain/user/model/user.model";
import UserApi from "../../core/api/user.api";

const AuthContext = createContext<any>({loggedUser: null, login: (user: User) => {}, logout: () => {}});

export const useAuth = () => useContext(AuthContext);

export interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({children}: AuthProviderProps) => {
    const [loggedUser, setLoggedUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true); // Ajout d'un état pour suivre le chargement

    useEffect(() => {
        const loadUser = async () => {
            try {
                const user = await UserApi.fetchProfile();
                setLoggedUser(user);
            } catch (error) {
                console.error("Erreur lors du chargement du profil de l'utilisateur", error);
                // Gérer l'erreur, par exemple en déconnectant l'utilisateur ou en affichant un message
            } finally {
                setIsLoading(false); // Assurez-vous de désactiver le chargement quelle que soit l'issue
            }
        };

        loadUser();
    }, []);

    const login = (user: User) => setLoggedUser(user);
    const logout = () => setLoggedUser(null);

    // Afficher un indicateur de chargement ou null pendant que isLoading est vrai
    if (isLoading) {
        return <div>Chargement...</div>; // Ou un composant de chargement personnalisé
    }

    return (
        <AuthContext.Provider value={{ loggedUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
