import ApiService from "./base.api";
import {UserBet, UserBetForCreation} from "../domain/bet/model/user-bet.model";
import {League} from "../domain/league/model/league.model";
import {User} from "../domain/user/model/user.model";

export class UserApi extends ApiService {
    async fetchUserLeagues(userId: number): Promise<League[]> {
        return this.get<League[]>(`/users/${userId}/leagues`).then(response => response.data);
    }

    async saveUserBet(userBet: UserBetForCreation): Promise<UserBet> {
        return this.post<UserBet>('/user-bets', userBet).then(response => response.data);
    }

    async fetchProfile(): Promise<User> {
        return this.get<User>('/users/profile').then(response => {
            return response.data
        });
    }
}

export default new UserApi(); // On exporte une instance pour l'utiliser directement
