import {Fixture} from "../../fixture/fixture.model";
import {User} from "../../user/model/user.model";
import {League} from "../../league/model/league.model";

export interface UserBet {
    id: number;
    fixture: Fixture;
    user: User;
    league: League;
    amount: number;
    selectedOdds: number;
    betType: BetType;
    updatedDate: Date;
    result: BetResult;
    status: BetStatus;
    winnings: number;
}

export interface UserBetForCreation {
    id?: number;
    fixtureId: number;
    userId: number;
    leagueId: number;
    amount?: number;
    selectedOdds?: number;
    betType?: BetType;
    result?: BetResult;
}

export interface UserBetFilter {
    round?: number;
    leagueId: number;
    userId?: number;
}

export interface BetResult {
    winner: number;
    awayScore?: number | null;
    homeScore?: number | null;
    diffGoals?: number | null;
}

export enum BetStatus {
    PENDING = 'PENDING', LOST = 'LOST', WIN = 'WIN', MISSED = 'MISSED'
}

/*export interface WinnerResult extends BetResult {
    winner: number;

}

export interface ScoreResult extends BetResult {
    away: number;
    home: number;

}*/

/*export type BetResultType = ScoreResult | WinnerResult;*/

export enum BetType {
    WINNER = 'WINNER',
    WINNER_DIFF_GOAL = 'WINNER_DIFF_GOAL',
    EXACT_SCORE = 'EXACT_SCORE',
}

export class UserBetFilter {
    round?: number;
    userId?: number;
}