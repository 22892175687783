import ApiService from "./base.api";
import {User} from "../domain/user/model/user.model";

export class AuthentificationApi extends ApiService {
    login(login: string, password: string): Promise<User> {
        return this.post<User>('/auth/login', {login, password}).then(response => {
            return response.data
        });
    }



}

export default new AuthentificationApi();
