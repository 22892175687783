import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, TextField, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {CircleFlag} from "react-circle-flags";
import React, {useState} from "react";
import {Fixture} from "../../../../core/domain/fixture/fixture.model";


interface ExactResultFormProps {
    homeScore: number | null;
    awayScore: number | null;
    onAwayScoreChanged: (value:number | null) => void;
    onHomeScoreChanged: (value:number | null ) => void;

}
export const ExactResultForm = (props: ExactResultFormProps) => {

    const {onAwayScoreChanged, onHomeScoreChanged, homeScore, awayScore } = props;

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel3a-content"
                id="panel3a-header"
            >
                <Typography>Je paris sur le résultat exact</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container columns={13}>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                borderRadius: 1,
                            }}
                        >
                            <TextField type="number"
                                       value={homeScore !== null && homeScore !== undefined ? homeScore : ""}
                                       sx={{mx: 2, width: '60px', height: '60px', textAlign: 'center'}} inputProps={{ min: 0, max: 9 }} id="outlined-basic"
                                       onChange={(event) => {{
                                           const value = event.target.value === "" ? null : Number(event.target.value);
                                           onHomeScoreChanged(value)
                                       }}}
                                       variant="outlined"/>
                        </Box>
                    </Grid>
                    <Grid item xs={1}> - </Grid>
                    <Grid item xs={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderRadius: 1,
                            }}
                        >
                            <TextField type="number"
                                       value={awayScore !== null && awayScore !== undefined ? awayScore : ""}
                                       sx={{mx: 2, width: '60px', height: '60px', textAlign: 'center'}} inputProps={{ min: 0, max: 9 }} id="outlined-basic"
                                       onChange={(event) => {{
                                           const value = event.target.value === "" ? null : Number(event.target.value);
                                           onAwayScoreChanged(value)
                                       }}}
                                       variant="outlined"/>
                        </Box>
                    </Grid>
                </Grid>

            </AccordionDetails>
        </Accordion>
    )
}