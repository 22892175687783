import {Box, Button, Divider, OutlinedInput} from "@mui/material";
import {calculateOdds} from "../../../../core/domain/bet/bet-utils";
import React from "react";
import {Fixture} from "../../../../core/domain/fixture/fixture.model";
import {BetResult, BetStatus} from "../../../../core/domain/bet/model/user-bet.model";
import {BetResultLabel} from "../bet-result-label/bet-result-label.component";
import {formatNumber} from "../../../../core/utils/number.utils";


interface BetInfoProps {
    fixture: Fixture; // Replace 'Fixture' with your actual type
    amount: number | null;
    betResult: BetResult | null;

    onAmountChanged: (value: number | null) => void;
}

export const BetInfo = (props: BetInfoProps) => {
    const {betResult, fixture, amount, onAmountChanged} = props;

    const handleAmountChange = (event: any) => {
        const {value} = event.target;
        // Vérifie si la valeur est un nombre entier
        if (/^\d*$/.test(value)) {
            onAmountChanged(value);
        }
    };

    if (!betResult) {
        return null;
    }
    const {winner, diffGoals, awayScore, homeScore,} = betResult;

    if (!winner) {
        return <></>
    }
    const odds = calculateOdds(fixture, betResult);
    return (
        <>
            <Box sx={{p: 2, textAlign: 'left'}}>
                <Box sx={{fontWeight: 'medium', textAlign: 'left'}}>Resultat</Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1}}>
                    <BetResultLabel betResult={betResult} fixture={fixture}
                                    betStatus={BetStatus.PENDING}></BetResultLabel>
                    <Box>
                        <div>Cote</div>

                        <div>{formatNumber(odds, 2)}</div>
                    </Box>
                </Box>


            </Box>
            <Divider orientation="horizontal" sx={{my: 1}}/>
            <Box sx={{
                px: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1
                }}>
                    <Button sx={{minWidth: 0}} variant="outlined" onClick={() => {
                        if ((amount || 0) > 0) {
                            onAmountChanged((amount || 0) - 1)
                        }
                    }}>
                        -
                    </Button>
                    <OutlinedInput value={amount} placeholder="Ma mise"
                                   size={'small'}
                                   inputProps={{maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*'}}
                                   sx={{maxWidth: '5rem', my: 2}}
                                   onChange={handleAmountChange}/>
                    <Button sx={{minWidth: 0}} variant="outlined"
                            onClick={() => onAmountChanged((amount || 0) + 1)}>
                        +
                    </Button>
                </Box>
                <Box>
                    <div>Gains</div>
                    <div>{((amount || 0) * odds).toFixed(2)}K</div>
                </Box>

            </Box>
            <Divider orientation="horizontal" sx={{my: 1}}/>
        </>
    )
}