import React, {useEffect, useState} from "react";
import FixtureApi from "../../core/api/fixture.api";
import {Fixture} from "../../core/domain/fixture/fixture.model";
import {Box, Modal} from "@mui/material";
import BetForm from "./bet-form/bet-form";
import {UserBet, UserBetForCreation} from "../../core/domain/bet/model/user-bet.model";
import UserBetApi from "../../core/api/user-bet.api";
import {BetItem} from "./bet-item/bet-item.component";
import {League} from "../../core/domain/league/model/league.model";
import {RoundNavigator} from "../../shared/league/round-navigator/round-navigator";
import {useAuth} from "../../shared/security/auth-context";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
    textAlign: 'center'
};

export interface MyBetsContainerProps {
    league: League | null;
}


const MyBetsContainer = (props: MyBetsContainerProps) => {
    const {loggedUser} = useAuth();
    const {league} = props;
    const [fixtures, setFixtures] = useState<Fixture[]>([]);
    const [userBets, setUserBets] = useState<UserBet[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentBet, setCurrentBet] = useState<UserBet | null>(null);
    const [currentFixture, setCurrentFixture] = useState<Fixture | null>(null);
    const [round, setRound] = React.useState<number>(league?.currentRound || 1);

    //const [currentFormBet, setCurrentFormBet] = useState<BetFormModel | null>(null);

    const [open, setOpen] = React.useState(false);
    const openBet = (fixture: Fixture) => {
        setCurrentBet(userBets.find(userBet => userBet.fixture.id === fixture.id) || null);
        setCurrentFixture(fixture)
        setOpen(true);
    }
    const handleClose = () => {

        setOpen(false);
    }


    useEffect(() => {
        loadData(round)
    }, []);

    const loadData = async (roundToLoad: number) => {
        await loadFixture(roundToLoad);
        await loadUserBets(roundToLoad);
        setLoading(false);
    }
    const loadFixture = async (roundFixture: number) => {
        try {
            const fixtures: Fixture[] = await FixtureApi.fetchFixtures({
                round: roundFixture,
                competitionId: league?.competition.id
            });
            setFixtures(fixtures.sort((a, b) => a.date.localeCompare(b.date)));
        } catch (error) {
            setLoading(false);
        }
    };

    const loadUserBets = async (roundToLoad: number) => {
        const userBets: UserBet[] = await UserBetApi.fetchUserBets({
            userId: loggedUser.id,
            leagueId: league?.id!,
            round: roundToLoad
        });
        setUserBets(userBets)
    }
    const saveUserBet = async (userBet: UserBetForCreation) => {

        //await UserBetApi.saveUserBet(userBet)
        await loadUserBets(round)
        setOpen(false)


    }


    return (
        <>
            <RoundNavigator round={round} maxRound={league!.maxRound} handleChange={(roundToLoad) => {
                setRound(roundToLoad);
                loadData(roundToLoad);
            }}></RoundNavigator>
            <Box>
                {fixtures.map(item =>
                    <BetItem key={item.id} fixture={item} editBet={openBet}
                             userBet={userBets.find(userBet => userBet.fixture.id === item.id)}></BetItem>
                )}
            </Box>
            {currentFixture && <Modal

                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <BetForm userBet={currentBet} fixture={currentFixture} onSubmit={saveUserBet}></BetForm>
                </Box>
            </Modal>
            }
        </>
    );
}

export default MyBetsContainer;