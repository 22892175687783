import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {BrowserRouter, createBrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./features/login/login";
import PrivateRoutes from "./shared/security/private-route";
import DashboardComponent from "./features/dashboard/dashboard.component";
import {LeagueContainer} from "./features/league/league.container";
import {AuthProvider} from './shared/security/auth-context';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },
]);

root.render(
    <BrowserRouter>
        <AuthProvider>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route element={<PrivateRoutes/>}>
                    <Route path="/" element={<App/>}>
                        <Route index element={<DashboardComponent/>}/>
                        <Route path="/leagues/:leagueId" element={<LeagueContainer/>}/>
                    </Route>

                    {/* Autres routes privées ici */}
                </Route>
            </Routes>
        </AuthProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
