import {RoundNavigator} from "../../shared/league/round-navigator/round-navigator";
import React, {useEffect} from "react";
import {League, LeagueRoundResults, UserRoundResult} from "../../core/domain/league/model/league.model";
import {Box, Typography} from "@mui/material";
import LeagueApi from "../../core/api/league.api";
import {RoundResultFixture} from "./round-result-fixture";
import FixtureApi from "../../core/api/fixture.api";
import {Fixture} from "../../core/domain/fixture/fixture.model";

export interface LeagueHistoryProps {
    league: League;
}

export const LeagueHistory = (props: LeagueHistoryProps) => {
    const {league} = props;
    const [round, setRound] = React.useState<number>(league?.currentRound || 1);
    const [results, setResults] = React.useState<UserRoundResult[]>([]);
    const [fixtures, setFixtures] = React.useState<Fixture[]>([]);
    const loadData = async (roundToLoad: number) => {
        setFixtures((await FixtureApi.fetchFixtures({
            competitionId: league.competition.id,
            round: roundToLoad
        })).sort((a, b) => a.date.localeCompare(b.date)));
        const results: LeagueRoundResults = await LeagueApi.getLeagueResultsFor(league.id, roundToLoad);
        setResults(results.userResults);

    }

    useEffect(() => {
        loadData(round);
    }, []);

    return (<>
        <div>Historique</div>
        <RoundNavigator round={round} maxRound={league.maxRound} handleChange={(roundToLoad) => {
            setRound(roundToLoad);
            loadData(roundToLoad);
        }}></RoundNavigator>


        {results.map(userResult => {
            const calculateGains = (): number => {
                return userResult.bets
                    .map(bet => Math.round(bet.winnings > 0 ? (bet.winnings + (bet.amount * -1)) : (bet.amount * -1)))
                    .reduce((acc, value) => acc + value, 0);
            };
            return (<Box
                key={'user-results-' + userResult.user.userId}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    textAlign: 'start',
                    border: '1px solid #b4b1b1',
                    padding: '0.5rem',
                    marginTop: '1rem',
                    borderRadius: '5px',
                }}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography align={'center'} sx={{fontWeight: 'bold'}}>{userResult.user.name}</Typography>
                    <Typography sx={{float: 'right'}}>Gain: {calculateGains() + 'K'}</Typography>
                </Box>
                {fixtures.map(fixture => {
                    const userBet = userResult.bets.find(u => u.fixture.id === fixture.id);
                    return (<RoundResultFixture key={'round-result-fixture' + fixture.id} userBet={userBet}
                                                fixture={fixture}></RoundResultFixture>)
                })}
            </Box>)
        })}

    </>)
}

