import {Accordion, AccordionDetails, AccordionSummary, Slider, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";


export interface DiffGoalSliderProps {
    nbGoals: number;
    onNbGoalsChanged: (value: number) => void
}
export const DiffGoalSlider = (props: DiffGoalSliderProps) => {
    return (
        <Accordion defaultExpanded={props.nbGoals > 0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography>Je paris avec la différence de but</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Slider  onChange={(event, value) => props.onNbGoalsChanged(Number(value))} defaultValue={0} value={props.nbGoals} max={10} marks valueLabelDisplay="on"/>
            </AccordionDetails>
        </Accordion>
    )
}