import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {TeamLogo} from "../../../../shared/team/team-logo/team-logo";
import React from "react";
import {Fixture} from "../../../../core/domain/fixture/fixture.model";


interface FixtureWinnerSelectionProps {
    fixture: Fixture; // Replace 'Fixture' with your actual type
    winner: number | null;
    onWinnerChange: (value: number | null) => void;
}
export const FixtureWinnerSelection = (props: FixtureWinnerSelectionProps) => {
    const {fixture, winner, onWinnerChange} = props;
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                bgcolor: 'background.paper',
                justifyContent: 'space-around',
                borderRadius: 1,
            }}>
                <ToggleButtonGroup
                    value={winner}
                    exclusive
                    aria-label="text alignment"
                    onChange={(event, value) => {
                        onWinnerChange(value)
                    }}>
                    <ToggleButton value={fixture.teams.home.id} sx={{px: 2}}>
                        <Box sx={{display: 'flex',  flexDirection: 'column'}}>
                            <TeamLogo teamName={fixture.teams.home.name}
                                      isFlex={true}
                                      logoUrl={fixture.teams.home.logo}></TeamLogo>
                            <div>({fixture.odds?.home})</div>
                        </Box>

                    </ToggleButton>
                    <ToggleButton value={-1} aria-label="centered" sx={{px: 3}}>
                        Nul ({fixture.odds?.draw})
                    </ToggleButton>
                    <ToggleButton value={fixture.teams.away.id} aria-label="right aligned" sx={{px: 2}}>
                        <Box sx={{display: 'flex',  flexDirection: 'column'}}>
                        <TeamLogo teamName={fixture.teams.away.name}
                                  isFlex={true}
                                  logoUrl={fixture.teams.away.logo}></TeamLogo>
                        ({fixture.odds?.away})
                        </Box>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </>
    )
}