import ApiService from "./base.api";
import {UserBet, UserBetFilter, UserBetForCreation} from "../domain/bet/model/user-bet.model";


export class UserBetApi extends ApiService {
    fetchUserBets(filter: UserBetFilter): Promise<UserBet[]> {

        return this.get<UserBet[]>('/user-bets', filter).then(response => response.data);
    }

    saveUserBet(userBet: UserBetForCreation): Promise<UserBet> {
        return this.post<UserBet>('/user-bets', userBet).then(response => response.data);
    }
}

export default new UserBetApi(); // On exporte une instance pour l'utiliser directement
