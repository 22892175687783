import {Box, Divider, Typography} from "@mui/material";
import React from "react";
import {BetStatus, UserBet} from "../../core/domain/bet/model/user-bet.model";
import {RoundResultIcon} from "./round-result-icon";
import {RoundResultFixtureBet} from "./round-result-fixture-bet";
import {Fixture, FixtureStatus} from "../../core/domain/fixture/fixture.model";
import dayjs from "dayjs";

export interface RoundResultFixtureProps {
    userBet?: UserBet;
    fixture: Fixture;
}

export const RoundResultFixture = (props: RoundResultFixtureProps) => {
    const {userBet, fixture} = props;

    const isMatchNotStarted = fixture.status === FixtureStatus.NOT_STARTED;
    const displayScore = (): string => {
        return !isMatchNotStarted ? `(${fixture.score.home} - ${fixture.score.away})` : '';
    };
    return (
        <>
            <Divider sx={{marginY: '0.3rem'}}/>
            <Box>
                {isMatchNotStarted ?
                    <Typography sx={{
                        float: 'right',
                        color: '#9e9e9e',
                        fontStyle: 'italic',
                        fontSize: '12px'
                    }}>{dayjs(fixture.date).format("DD/MM HH:mm")}</Typography> :
                    <RoundResultIcon status={userBet?.status || BetStatus.MISSED}></RoundResultIcon>}
                <Typography>{fixture.teams.home.name} - {fixture.teams.away.name} {displayScore()}</Typography>
                <Box sx={{display: 'flex', flexDirection: 'row',}}>
                    {!isMatchNotStarted && <RoundResultFixtureBet userBet={userBet}></RoundResultFixtureBet>}
                    {userBet && <Typography sx={{fontSize: 'small'}}>| {userBet.amount}K | Côte: ({userBet.selectedOdds}) |
                        Gain: {userBet.winnings}K</Typography>}
                </Box>


            </Box>

        </>

    )
}