import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {useNavigate} from "react-router-dom";

class ApiService {
    protected http: AxiosInstance;

    constructor() {
        console.log(process.env.REACT_APP_API_URL)
        this.http = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            // Vous pouvez également ajouter des headers par défaut, des timeouts, etc.
        });

        const getToken = () => localStorage.getItem('token');

        this.http.interceptors.response.use(
            res => res,
            error => {
                console.log(error);
                if (error.response && error.response.status === 401) {
                    const navigate = useNavigate();
                    // Redirigez vers la page de login
                    navigate('/login');
                }
                /*toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })*/
                console.log(error.response.data);
                throw new Error(error.response.data.message);
            }
        )

        this.http.interceptors.request.use(
            config => {
                const token = getToken();
                if (token) {
                    // Si le token existe, ajoutez-le à l'en-tête de la requête
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            error => {

                // Gérez les erreurs de requête ici
                return Promise.reject(error);
            }
        );
    }

    get<T>(url: string, params?: object): Promise<AxiosResponse<T>> {
        return this.http.get<T>(url, { params });
    }

    post<T>(url: string, data?: object): Promise<AxiosResponse<T>> {
        return this.http.post<T>(url, data);
    }

    put<T>(url: string, data?: object): Promise<AxiosResponse<T>> {
        return this.http.put<T>(url, data);
    }

    delete<T>(url: string): Promise<AxiosResponse<T>> {
        return this.http.delete<T>(url);
    }
}

export default ApiService;