import {Box, Button} from "@mui/material";
import React from "react";

export interface RoundNavigatorProps {
    round: number;
    maxRound: number;
    handleChange: (roundToLoad: number) => void;
}


export const RoundNavigator = (props: RoundNavigatorProps) => {
    const {round, maxRound, handleChange} = props;
    return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Button
            sx={{my: 0.5}}
            variant="outlined"
            size="small"
            onClick={() => handleChange(round - 1)}
            disabled={round === 1}
            aria-label="move selected right"
        >
            &lt;
        </Button>
        <Box sx={{mx: 2}}>Journée: {round}</Box>
        {maxRound > round && <Button
            sx={{my: 0.5}}
            variant="outlined"
            size="small"
            onClick={() => handleChange(round + 1)}
            disabled={round === 38}
            aria-label="move selected left"
        >
            &gt;
        </Button>}
    </Box>
}