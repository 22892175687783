import {Box} from "@mui/material";

interface TeamLogoProps {
    teamName?: string;
    logoUrl?: string;
    isFlex?: boolean;
    logoSize?: number;
}

export const TeamLogo = (props: TeamLogoProps) => {
    const {teamName, logoUrl, isFlex, logoSize} = props;

    return (
        <Box sx={{display: isFlex ? 'flex' : 'block', alignItems: 'center'}}>
            <Box sx={{mr: 1}}>
                <img src={logoUrl} alt={`${teamName} Logo`} width={logoSize || 35} height={logoSize || 35}/>
            </Box>
            <Box sx={{textAlign: 'center'}}>{teamName}</Box>
        </Box>
    )
}