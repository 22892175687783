import {Typography} from "@mui/material";
import React from "react";
import {BetStatus, BetType, UserBet} from "../../core/domain/bet/model/user-bet.model";
import {FixtureUtils} from "../../core/domain/fixture/fixture.utils";

export interface RoundResultFixtureBetProps {
    userBet?: UserBet;
}

export const RoundResultFixtureBet = (props: RoundResultFixtureBetProps) => {
    const {userBet} = props;
    const color = !userBet ? 'grey' : userBet.status === BetStatus.WIN ? '#42b542' : '#df6c6c';

    const displayResult = (): string => {
        if(!userBet) {
            return `Aucun pari`
        }
        switch (userBet.betType) {
            case BetType.WINNER : {
                const vainqueur = FixtureUtils.getTeamBy(userBet.fixture, userBet.result.winner)?.name;
                return vainqueur ? `Vainqueur ${vainqueur}` : 'Match nul';
            }
            case BetType.WINNER_DIFF_GOAL : {
                return `${FixtureUtils.getTeamBy(userBet.fixture, userBet.result.winner)?.name || ''} +${userBet.result.diffGoals} buts`
            }
            case BetType.EXACT_SCORE : {
                return `Score exact ${userBet.result.homeScore} - ${userBet.result.awayScore}`
            }
        }
    }
    return (
        <Typography sx={{
            backgroundColor: color,
            borderRadius: '5px',
            color: 'white',
            fontSize: 'small',
            marginRight: '0.5rem',
            paddingLeft: '0.3rem',
            paddingRight: '0.3rem'
        }}>{displayResult()}</Typography>

    )
}