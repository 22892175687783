import ApiService from "./base.api";
import {Fixture, FixtureFilter} from "../domain/fixture/fixture.model";

export class FixtureApi extends ApiService {
    fetchFixtures(fixtureFilter?: FixtureFilter): Promise<Fixture[]> {
        const queryParams = {
            round: fixtureFilter?.round,
            competitionId: fixtureFilter?.competitionId
        }
        return this.get<Fixture[]>('/fixtures',  queryParams ).then(response => response.data);
    }
}

export default new FixtureApi(); // On exporte une instance pour l'utiliser directement
