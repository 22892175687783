import ApiService from "./base.api";
import {UserBet, UserBetForCreation} from "../domain/bet/model/user-bet.model";
import {League, LeagueRoundResults} from "../domain/league/model/league.model";


export class LeagueApi extends ApiService {
    getLeagueById(leagueId: number): Promise<League> {
        return this.get<League>('/leagues/'+leagueId).then(response => response.data);
    }

    getLeagueResultsFor(leagueId: number, round: number): Promise<LeagueRoundResults> {
        return this.get<LeagueRoundResults>('/leagues/'+leagueId+'/rounds/'+round+'/results').then(response => response.data);
    }
}

export default new LeagueApi();
