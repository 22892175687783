import React, {useEffect, useState} from "react";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {BetResult, BetType, UserBet, UserBetForCreation} from "../../../core/domain/bet/model/user-bet.model";
import {Fixture} from "../../../core/domain/fixture/fixture.model";
import {calculateOdds} from "../../../core/domain/bet/bet-utils";
import {FixtureWinnerSelection} from "./team-selection/team-selection.component";
import {DiffGoalSlider} from "./diff-goal-slider/diff-goal-slider.component";
import {ExactResultForm} from "./exact-result-form/exact-result-form.component";
import {BetInfo} from "./bet-info/bet-info.component";
import {BetButton} from "./bet-button/bet-button.component";
import UserBetApi from "../../../core/api/user-bet.api";
import {useAuth} from "../../../shared/security/auth-context";
import {useParams} from "react-router-dom";

interface BetFormProps {
    userBet: UserBet | null;
    fixture: Fixture;
    onSubmit: (userBet: UserBetForCreation) => void;
}

const BetForm = (props: BetFormProps) => {
    const params = useParams();
    let {userBet, fixture, onSubmit} = props;
    const [winner, setWinner] = useState<number | null>(userBet?.result?.winner || null);
    const [amount, setAmount] = useState<number | null>(userBet?.amount || 0)
    const [diffGoals, setDiffGoals] = useState<number>(userBet?.result?.diffGoals || 0);
    const [awayScore, setAwayScore] = useState<number | null>(userBet?.result?.awayScore || null);
    const [homeScore, setHomeScore] = useState<number | null>(userBet?.result?.homeScore || null);
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const {loggedUser} = useAuth();

    useEffect(() => {
        setError(null);
    }, [amount]);
    const buildBetResult = (): BetResult | null => {
        if(!winner) {
            return null;
        }
        return  {
            winner: winner,
                diffGoals: diffGoals,
                awayScore: awayScore,
                homeScore: homeScore
        }
    }
    const saveUserBet = async (): Promise<void> => {
        if (winner && amount) {
            setLoading(true);
            let betType = BetType.WINNER;
            if(awayScore !== null && homeScore !== null) {
                betType = BetType.EXACT_SCORE;
            } else if(diffGoals > 0) {
                betType = BetType.WINNER_DIFF_GOAL
            }

            const userBetForCreation: UserBetForCreation = {
                id: userBet?.id,
                fixtureId: fixture.id,
                userId: loggedUser.id,
                leagueId: Number(params.leagueId),
                amount: amount,
                selectedOdds: calculateOdds(fixture, buildBetResult()),
                betType: betType,
                result: {
                    winner: winner,
                    diffGoals: diffGoals,
                    awayScore: awayScore,
                    homeScore: homeScore
                }
            }
            try {
                await UserBetApi.saveUserBet(userBetForCreation)
                onSubmit(userBetForCreation);
            } catch(error: any) {
                setError(error.message);
            } finally {
                setLoading(false);
            }

        }
    }

    const resetBonus = () => {
        setDiffGoals(0)
        setAwayScore(null);
        setHomeScore(null);
    }
    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{mb: '1rem'}}>
                    <FixtureWinnerSelection fixture={fixture} winner={winner}
                                            onWinnerChange={(value) => {
                                                resetBonus()
                                                setWinner(value)
                                            }}></FixtureWinnerSelection>
                </Grid>
                <Grid item xs={12}>
                    {winner && winner !== -1 &&
                        <DiffGoalSlider nbGoals={diffGoals} onNbGoalsChanged={(value: number) => {
                            setAwayScore(null);
                            setHomeScore(null);
                            setDiffGoals(value)
                        }}></DiffGoalSlider>}
                    {winner && <ExactResultForm
                        awayScore={awayScore}
                        homeScore={homeScore}
                        onAwayScoreChanged={(value) => {
                            setDiffGoals(0);
                            setAwayScore(value);
                        }} onHomeScoreChanged={(value) => {
                        setDiffGoals(0);
                        setHomeScore(value);
                    }}></ExactResultForm>}
                </Grid>
                <Grid item xs={12}>

                </Grid>
            </Grid>
            {winner &&
                <Paper elevation={4} sx={{mt: 2}}>
                    <BetInfo fixture={fixture} amount={amount}
                             betResult={buildBetResult()}
                             onAmountChanged={setAmount}></BetInfo>
                    <BetButton onClick={saveUserBet}  betResult={buildBetResult()} fixture={fixture} amount={amount} loading={loading}></BetButton>
                    {error && (
                        <Box>
                            <Typography color="error" style={{marginTop: '8px'}}>
                                {error}
                            </Typography>
                        </Box>
                    )}
                </Paper>
            }
        </>
    );
}

export default BetForm;