import { Outlet, Navigate } from 'react-router-dom'
import {useAuth} from "./auth-context";

const PrivateRoutes = () => {
    //let token = localStorage.getItem('token');
    const {loggedUser} = useAuth();
    return(
        loggedUser?.token ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes