import CheckIcon from "@mui/icons-material/Check";
import {green, grey, red} from "@mui/material/colors";
import React from "react";
import {BetStatus} from "../../core/domain/bet/model/user-bet.model";
import CloseIcon from "@mui/icons-material/Close";

export interface RoundResultIconProps {
    status: BetStatus;
}

export const RoundResultIcon = (props: RoundResultIconProps) => {
    const {status} = props;
    return (
        <>
            {status === BetStatus.WIN && <CheckIcon sx={{m: 0, float: 'right', marginTop: '0.5rem', color: green[500]}}
                                                    fontSize="small"></CheckIcon>
            }
            {status === BetStatus.LOST && <CloseIcon sx={{m: 0, float: 'right', marginTop: '0.5rem', color: red[500]}}
                                                     fontSize="small"></CloseIcon>
            }
            {status === BetStatus.MISSED && <CloseIcon sx={{m: 0, float: 'right', marginTop: '0.5rem', color: grey[500]}}
                                                     fontSize="small"></CloseIcon>
            }
        </>

    )
}