export interface Fixture {
    id: number;
    date: string;
    status: string;
    teams: {
        home: Team;
        away: Team;
    };
    score: {
        home: number;
        away: number;
    };
    odds?: Odds;
}

export interface Team {
    id: number;
    name: string;
    logo?: string;
    winner?: boolean;
}

export interface Odds {
    home: number;
    draw: number;
    away: number;
}

export class FixtureFilter {
    round?: number;
    competitionId?: number;
}

export enum FixtureStatus {
    FINISHED = 'FT',
    NOT_STARTED = 'NS'
}
