import {Box, Tab, Tabs} from "@mui/material";
import MyBetsContainer from "../my-bets/my-bets.container";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {League} from "../../core/domain/league/model/league.model";
import LeagueApi from "../../core/api/league.api";
import LeagueOverview from "../home/league-overview/league-overview";
import {LeagueHistory} from "../history/history";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 1}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/****
 CONTAINER
 */
export const LeagueContainer = () => {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [value, setValue] = React.useState(0);
    const [league, setLeague] = useState<League | null>(null);

    useEffect(() => {
        loadData()
    }, []);

    const loadData = async () => {
        await loadLeague();
        setLoading(false);
    }

    const loadLeague = async () => {
        const league: League = await LeagueApi.getLeagueById(Number(params.leagueId));
        setLeague(league);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Home" {...a11yProps(0)} />
                    <Tab label="Mes paris" {...a11yProps(1)} />
                    <Tab label="Historique" {...a11yProps(2)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <LeagueOverview league={league}></LeagueOverview>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <MyBetsContainer league={league}></MyBetsContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                 <LeagueHistory league={league!}></LeagueHistory>
            </CustomTabPanel>
        </>

    )
}