import {Box, Button, CircularProgress, Typography} from "@mui/material";
import React from "react";
import {BetResult} from "../../../../core/domain/bet/model/user-bet.model";
import {Fixture} from "../../../../core/domain/fixture/fixture.model";

export interface BetButtonProps {
    betResult: BetResult | null;
    fixture: Fixture;
    loading: boolean;
    onClick: () => void;
    amount: number | null;
}

export const BetButton = (props: BetButtonProps) => {


    const {onClick, betResult, fixture, amount, loading} = props;
    if (!betResult) {
        return null;
    }

    const {winner, awayScore, homeScore} = betResult;
    const getBetInvalidReason = (): (string | null) => {
        if (!amount || amount <= 0) {
            return "montant du pari obligatoire"
        }
        //Verification d'un score valide
        if (awayScore !== null || homeScore !== null) {
            //Si les 2 scores n'ont pas été rempli
            if (homeScore === null || awayScore === null || homeScore === undefined || awayScore === undefined) {
                return "score incomplet";
            }
            if ((homeScore === awayScore && winner !== -1)
                || (homeScore > awayScore && winner !== fixture.teams.home.id)
                || (homeScore < awayScore && winner !== fixture.teams.away.id)
            ) {
                return "score invalide"
            }

        }
        return null;
    }

    const error = getBetInvalidReason();

    return (
        <> <Box sx={{display: 'inline-flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Button sx={{my: 2}} variant="contained" disabled={error !== null || loading} onClick={onClick}>
                {loading &&
                <CircularProgress
                size="1rem" sx={{color: 'white', marginRight: '0.5rem'}}/>}Parier</Button>


            {!!error && (
                <Box>
                    <Typography color="error" style={{marginTop: '8px'}}>
                        {error}
                    </Typography>
                </Box>
            )}
        </Box></>
    )
}