import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthentificationApi from "../../core/api/authentification.api";
import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import {AppBar, Toolbar} from "@mui/material";
import {useAuth} from "../../shared/security/auth-context";


function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © Khabet '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export default function LoginComponent() {
    const {login} = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async (event: any) => {
        event.preventDefault();
/*        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });*/
        if (username && password) {
            const user = await AuthentificationApi.login(username, password);
            localStorage.setItem('token', user.token);
            login(user);

            // Rediriger l'utilisateur vers la page d'accueil
            navigate('/');
        }
    };

    //style={{ backgroundImage: `url(/login-background-2.png)`, backgroundSize: 'cover'}}
    return (
        <Container maxWidth="md" disableGutters={true}>

            <Box sx={{flexGrow: 1}}>
                <AppBar position="static">
                    <Toolbar>
                        <img src="/logo_khabet_6.png" alt="Logo" style={{height: '50px', marginRight: '10px'}}/>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1, textAlign: 'center'}}>
                            Khabet
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <CssBaseline/>
            <Box
                sx={{
                    margin: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}

            >
                <img src="/logo_khabet.png" alt="Logo" style={{height: '150px'}}/>
                {/*<Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>*/}
                <Typography component="h1" variant="h5">
                    Se connecter
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Mot de passe oublié ?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Créer un compte"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{mt: 8, mb: 4}}/>

        </Container>
    );
}
