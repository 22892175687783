import {TeamLogo} from "../../../../shared/team/team-logo/team-logo";
import {Box} from "@mui/material";
import React from "react";
import {BetResult, BetStatus} from "../../../../core/domain/bet/model/user-bet.model";
import {Fixture} from "../../../../core/domain/fixture/fixture.model";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {green, red} from "@mui/material/colors";
export interface BetResultLabelProps  {
    betResult: BetResult;
    fixture: Fixture;
    betStatus: BetStatus;
}
export const BetResultLabel = (props: BetResultLabelProps) => {
    const {betResult, fixture, betStatus} = props;
    const {winner, diffGoals, awayScore, homeScore} = betResult
    return (
        <Box sx={{display: 'flex', alignItems: 'center', ml:1}}>
            <div> {winner === -1 && <span> Match nul</span>}</div>
            <div> {winner === fixture.teams.home.id &&
                <TeamLogo teamName={fixture.teams.home.name}
                          logoUrl={fixture.teams.home.logo}
                          logoSize={15}
                          isFlex={true}></TeamLogo>}</div>
            <div> {winner === fixture.teams.away.id &&
                <TeamLogo teamName={fixture.teams.away.name}
                          logoSize={15}
                          isFlex={true}
                          logoUrl={fixture.teams.away.logo}></TeamLogo>
            }</div>
            {(!!diffGoals && diffGoals > 0) && <Box sx={{ml: 1}}>+{diffGoals} buts</Box>}
            {awayScore !== null && homeScore !== null && awayScore !== undefined && homeScore !== undefined && <Box sx={{ml: 1}}>{homeScore} - {awayScore}</Box>}
            {betStatus === BetStatus.LOST && <CloseIcon sx={{ml:1, color: red[500]}}></CloseIcon>}
            {betStatus === BetStatus.WIN && <CheckIcon sx={{ml:1, color: green[500]}}></CheckIcon>}
        </Box>
    )
}