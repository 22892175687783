import {Box, Container} from '@mui/material';
import React from 'react';
import './App.css';
import {Outlet} from "react-router-dom";
import {AppBarContainer} from "./features/layout/app-bar";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

function App() {

    return (
        <Box className="App">
            <Container maxWidth="md" disableGutters={true}>

                <Box sx={{flexGrow: 1}}>
                    <AppBarContainer/>

                </Box>
                <Box sx={{paddingTop: '15px'}}>
                    <Outlet/>
                    {/*  <Routes>
                      <Route element={<PrivateRoutes />}>
                          <Route index element={<DashboardComponent/>} />
                          <Route path="leagues/1" element={<Home />} />


                           Autres routes privées ici
                      </Route>
                  </Routes>
*/}


                    {/*<LeagueContainer></LeagueContainer>*/}
                </Box>
            </Container>
            <ToastContainer />
        </Box>

    );
}

export default App;
