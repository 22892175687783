import {Avatar, Box, Button, Chip, Grid} from "@mui/material";
import React from "react";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useNavigate} from "react-router-dom";
import {League} from "../../../core/domain/league/model/league.model";
import CompetitionLogoComponent from "../competition-logo/competition-logo.component";


interface LeagueItemComponentProps {
    league: League
}

const LeagueItemComponent: React.FC<LeagueItemComponentProps> = ({league}) => {
    const navigate = useNavigate();

    const openLigue = (leagueId: number) => {
        navigate('/leagues/' + leagueId);
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}} onClick={() => openLigue(league.id)}>

            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{paddingBottom: '3rem'}}>
                        <CompetitionLogoComponent competition={league.competition}></CompetitionLogoComponent>
                        {/*<Chip icon={<AccessTimeIcon/>} label="12:50" variant="outlined"
                              sx={{backgroundColor: 'white', color: ' black', marginLeft: '2rem'}}/>*/}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" sx={{backgroundColor: 'white',}}>Fais tes paris</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}

export default LeagueItemComponent;